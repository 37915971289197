import { default as indexBF6xupegaaMeta } from "/Users/slavachekanovskiy/Projects/ocautoaid/pages/index.vue?macro=true";
import { default as _91service_93bYamw5PJOTMeta } from "/Users/slavachekanovskiy/Projects/ocautoaid/pages/services/[service].vue?macro=true";
import { default as indexKQESZkHJ7DMeta } from "/Users/slavachekanovskiy/Projects/ocautoaid/pages/services/index.vue?macro=true";
import { default as testimonials82WHQ8kMhJMeta } from "/Users/slavachekanovskiy/Projects/ocautoaid/pages/testimonials.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/Users/slavachekanovskiy/Projects/ocautoaid/pages/index.vue")
  },
  {
    name: "services-service",
    path: "/services/:service()",
    component: () => import("/Users/slavachekanovskiy/Projects/ocautoaid/pages/services/[service].vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/Users/slavachekanovskiy/Projects/ocautoaid/pages/services/index.vue")
  },
  {
    name: "testimonials",
    path: "/testimonials",
    component: () => import("/Users/slavachekanovskiy/Projects/ocautoaid/pages/testimonials.vue")
  }
]