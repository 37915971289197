export default defineAppConfig({
    theme: {
        height: {
            '0.5': '2px'
        }
    },
    ui: {
        primary: 'blue',
        horizontalNavigation: {
            container: 'gap-x-2.5',
            base: 'text-base text-slate-800 font-semibold',
            active: 'text-blue-700'
        },
        container: {
            padding: 'px-3 lg:px-1.5',
            constrained: 'max-w-full lg:max-w-7xl'
        },
        button: {
            padding: {
                '2xs': 'px-2 py-1',
                xs: 'px-2.5 py-1.5',
                sm: 'px-2.5 py-1.5',
                md: 'px-3 py-2',
                lg: 'px-3.5 py-2.5',
                xl: 'px-9 py-5'
            },
            font: 'font-semibold',
            size: {
                xl: 'text-base rounded-0.5xl'
            },
            square: {
                xl: 'p-5'
            },
            variant: {
                solid: 'bg-{color}-700'
            }
        },
        input: {
            rounded: 'rounded-0.5xl',
            color: {
                white: {
                    outline: 'ring-cool-800 dark:ring-cool-800 bg-slate-50'
                }
            },
            variant: {
                // outline: 'dark:ring-{color}-400'
            },
            default: {
                size: 'xl'
            }
        },
        textarea: {
            color: {
                white: {
                    outline: 'ring-cool-800 dark:ring-cool-800 bg-slate-50'
                }
            },
            default: {
                size: 'xl',
            }
        },
        checkbox: {
            wrapper: 'items-center',
            base: 'w-5 h-5 appearance-none border mr-0 cursor-pointer',
            // color: 'text-transparent dark:text-transparent',
            background: 'bg-transparent checked:bg-slate-800',
            border: 'border-slate-800 checked:border-slate-800 focus:border-slate-800',
            inner: 'ms-4'
        },
        formGroup: {
            label: {
                base: 'text-slate-800 font-medium'
            },
            default: {
                size: 'xl'
            }
        }
    },
    purge: {
        options: {
            safelist: {
                standard: [
                    'text-5xl',
                    'lg:text-5xl',
                    'text-7xl',
                    'lg:text-7xl',
                    'max-w-3xl',
                    'lg:max-w-3xl',
                    'py-36',
                    'py-12',
                    'pt-20 pt-16',
                    'lg:py-24',
                    'lg:py-36',
                    'gap-y-8',
                    'gap-y-9',
                    'gap-2.5',
                    'gap-10 gap-6',
                    'lg:gap-x-12',
                    'backdrop-blur',
                    'bg-white/10',
                    'px-7',
                    'bg-primary-700',
                    'sm:visible',
                    'bg-no-repeat',
                    'h-11',
                    'bg-slate-800',
                    'before:w-14',
                    'before:bg-blue-700',
                    'before:rounded',
                    'before:h-1',
                    'before:mb-4',
                    'md:flex-row',
                    'md:justify-between',
                    'grid-cols-[minmax(100px,544px)_1fr]',
                    'mt-8',
                    'lg:-mt-16',
                    'italic',
                    'bottom-14',
                    'left-14',
                    'right-14',
                    'px-8 py-6',
                    'grid-cols-2 col-span-2',
                    'shadow-dropdown'
                ],
            },
        }
    }
})
